/*================================================ 
    Страница загрузки приложения
================================================*/

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTelegramUserData from '../components/useTelegramUserData';
import { useUser, Friend } from '../contexts/context';
import CONSTANTS from '../components/constants';
import { BootScreen } from './bootScreen';
import WebApp from '@twa-dev/sdk';

interface FriendData {
    tgId: number,
    tgUsername: string,
    tgFirstName: string,
    tgLastName: string,
    tgPhotoUrl: string,
    tgIsBot: boolean
};

interface UserData {
    user: {
        locale: string;
        telegramUserID: number;
        telegramUsername: string;
        telegramFirstName: string;
        telegramLastName: string;
        telegramIsPremium: boolean;
        telegramPhotoUrl: string;
        telegramIsBot: boolean;
        email: string;
        wallet: string;
        score: number;
        energy: number;
        taps: number;
        botMined: number;
        lastSeenActivity: number;
        friends: number;
    },
    bonus: {
        big: number;
        medium: number;
        small: number;
    },
    dailyBoosts: {
        tapMaster: number;
        fullTank: number;
        adsShown: number;
    },
    skills: {
        tapIncrement: number;
        scoreIncrement: number;
        energyLimit: number;
        energyRestoration: number;
        tapBot: boolean;
        boostX2: boolean;
        boostX3: boolean;
        shareholderVote: boolean;
        shareholderVoteTONInvestment: number;
    },
    friendsArray: FriendData[];
}

export function BootPage() {

    const consoleAllowed = true;
    const alertAllowed = false;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const {updateUser, updateBonus, updateDailyBoosts, updateSkills, updateFriendsArray} = useUser();
    const [retryCount, setRetryCount] = useState<number>(0);
    const MAX_RETRIES = 2;

    const { telegramUserInfo } = useTelegramUserData();

    // Получение идентификатора приглашающего (передается как параметр "startapp")
    // Ссылка работает только, если параметр передан вот так: ?startapp=...
    // Все другие варианты (типа ?start=...) не работают
    const startParam = WebApp.initDataUnsafe.start_param || '';
    let referrerId = 0;
    if (startParam.startsWith('r_')) {
        referrerId = Number(startParam.slice(2)); // отрезает "r_" и оставляет остальное
        //console.log(referrerId); // будет "428856366"
    }
    
    if (alertAllowed) alert("[BOOT] referrerId: \n" + referrerId);

    // Обработчик массива друзей
    const processFriendsArray = (friendsArray: FriendData[]): Friend[] => {
        if (consoleAllowed) console.log("[processFriendsArray] Input array:", friendsArray);
        
        if (!Array.isArray(friendsArray)) {
            if (consoleAllowed) console.error("[processFriendsArray] Input is not an array");
            return [];
        }
      
        const processedFriends = friendsArray.map(friend => {
            if (consoleAllowed) console.log("[processFriendsArray] Processing friend:", friend);
          
            // Проверяем наличие всех необходимых полей
            if (!friend || typeof friend !== 'object') {
                if (consoleAllowed) console.warn("[processFriendsArray] Invalid friend object:", friend);
                return null;
            }
      
            // Проверяем точные имена полей
            return {
                tgId: friend.tgId,
                tgFirstName: friend.tgFirstName,
                tgLastName: friend.tgLastName,
                tgUsername: friend.tgUsername,
                tgPhotoUrl: friend.tgPhotoUrl,
                tgIsBot: friend.tgIsBot,
                //referrerId: referrerId
            };
        }).filter(friend => friend !== null) as Friend[];
      
        if (consoleAllowed) console.log("[processFriendsArray] Processed friends:", processedFriends);
        return processedFriends;
    };
    
    
    // Полуаем данные с сервера
    const fetchData = useCallback(async () => {
        if (!telegramUserInfo || !telegramUserInfo.id) {
            if (consoleAllowed) console.log('[BOOT.fetchData] No valid Telegram user info');
            return null;
        }

        if (alertAllowed) alert("[BOOT.fetchData] ]n" + "telegramUserInfo.language_code:" + telegramUserInfo.language_code);
        alert("[BOOT.fetchData] \n" + "telegramUserInfo.language_code: " + telegramUserInfo.language_code);

        try {
            const queryParams = new URLSearchParams({
                tg_user_id: String(telegramUserInfo.id),
                tg_username: encodeURIComponent(String(telegramUserInfo.username)),
                tg_first_name: encodeURIComponent(String(telegramUserInfo.first_name)),
                tg_last_name: encodeURIComponent(String(telegramUserInfo.last_name)),
                tg_locale: encodeURIComponent(String(telegramUserInfo.language_code)),
                tg_is_premium: String(telegramUserInfo.is_premium),
                tg_photo_url: String(telegramUserInfo.photo_url),
                tg_is_bot: String(telegramUserInfo.is_bot),
                tg_referrer_id: String(referrerId),
            });

            const requestUrl = `${CONSTANTS.server}${CONSTANTS.apiLinkGetUserRecords}?${queryParams.toString()}`;

            const response = await fetch(requestUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const data: UserData = await response.json();
            
            if (consoleAllowed)  console.log("[BOOT.fetchData] Received data:", data);

            // Обновляем контекст
            updateUser({
                locale: data.user.locale || CONSTANTS.defaultLocale,
                telegramUserID: data.user.telegramUserID || telegramUserInfo.id,
                telegramUsername: data.user.telegramUsername || telegramUserInfo.username || '',
                telegramFirstName: data.user.telegramFirstName || telegramUserInfo.first_name || '',
                telegramLastName: data.user.telegramLastName || telegramUserInfo.last_name || '',
                telegramIsPremium: data.user.telegramIsPremium || telegramUserInfo.is_premium || false,
                telegramPhotoUrl: telegramUserInfo.photo_url || '',
                isBot: telegramUserInfo.is_bot,
                email: data.user.email || '',
                wallet: data.user.wallet || '',
                score: data.user.score || CONSTANTS.iniScore,
                energy: data.user.energy || CONSTANTS.iniEnergy,
                taps: data.user.taps || 0,
                botMined: data.user.botMined || 0,
                lastSeenActivity: data.user.lastSeenActivity || 0,
                friends: data.user.friends || 0,
            });

            updateBonus({
                big: data.bonus.big || 0,
                medium: data.bonus.medium || 0,
                small: data.bonus.small || 0,
            });

            updateDailyBoosts({
                tapMaster: data.dailyBoosts.tapMaster || CONSTANTS.iniTapMaster,
                fullTank: data.dailyBoosts.fullTank || CONSTANTS.iniFullTank,
                adsShown: data.dailyBoosts.adsShown || 0,
            });

            updateSkills({
                tapIncrement: data.skills.tapIncrement || CONSTANTS.iniTapIncrement,
                activeTapIncrement: data.skills.tapIncrement || CONSTANTS.iniTapIncrement,
                scoreIncrement: data.skills.scoreIncrement || CONSTANTS.iniScoreIncrement,
                energyLimit: data.skills.energyLimit || CONSTANTS.iniEnergyLimit,
                energyRestoration: data.skills.energyRestoration || CONSTANTS.iniEnergyRestoration,
                tapBot: data.skills.tapBot || false,
                boostX2: data.skills.boostX2 || false,
                boostX3: data.skills.boostX3 || false,
                shareholderVote: data.skills.shareholderVote || false,
                shareholderVoteTONInvestment: data.skills.shareholderVoteTONInvestment || 0,
            });

            const friendsArray = processFriendsArray(data.friendsArray ?? []);

            if (consoleAllowed) console.log("[BOOT.fetchData] data.friendsArray:", data.friendsArray);
            if (consoleAllowed) console.log("[BOOT.fetchData] friendsArray:", friendsArray);
            updateFriendsArray(friendsArray);

            return data;
        } catch (error) {
            if (consoleAllowed) console.error('[BOOT.fetchData] Error loading user data:', error);
            return null;
        }
    }, [updateUser, updateBonus, updateDailyBoosts, updateSkills, updateFriendsArray, telegramUserInfo]);


    // Загружаем данные с сервера при рендеринге страницы
    useEffect(() => {
        if (!telegramUserInfo || !telegramUserInfo.id) {
            if (consoleAllowed) console.log("[BOOT.useEffect] Waiting for Telegram user info...");
            return;
        }

        const loadUserData = async () => {
            try {
                setIsLoading(true);
                const result = await fetchData();

                if (result) {
                    // Успешная загрузка данных
                    if (consoleAllowed) console.log("[BOOT.useEffect] Data loaded successfully");
                    navigate('/tap'); // Перенаправление после загрузки
                } else if (retryCount < MAX_RETRIES) {
                    if (consoleAllowed) console.log(`[BOOT.useEffect] Retry attempt ${retryCount + 1}`);
                    setRetryCount(prev => prev + 1);
                }
            } catch (error) {
                if (consoleAllowed) console.error('[BOOT.useEffect] Error in loadUserData:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadUserData();
    }, [telegramUserInfo, fetchData, retryCount, navigate]);

    return <BootScreen />;
}