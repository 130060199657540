// -------------------------------------
// Часто используемые функции
// -------------------------------------

// ------------------------------------------------------
// Обрезание строки до первых нескольких букв и последних несольких букв

export function truncateString(str: string, startChars: number, endChars: number): string {
    
    // Проверяем, что str определена и является строкой
    if (!str || typeof str !== 'string') {
        return ''; // Возвращаем пустую строку, если str не определена или не строка
    }
    
    // Если длина строки меньше или равна сумме символов начала и конца, возвращаем строку без изменений
    if (str.length <= startChars + endChars) {
        return str;
    }

    // Получаем нужное количество символов в начале и в конце строки
    const start = str.slice(0, startChars);
    const end = str.slice(-endChars);

    return `${start}...${end}`;
}

// ------------------------------------------------------
// Форматирование чисел: разделение на разряды и количество знаков после запятой (уже не используется)

export const formatNumber = (value: number, decimalPlaces: number = 5) => {
    // Устанавливаем фиксированное количество дробных знаков
    const fixedValue = value.toFixed(decimalPlaces);
    
    const [integerPart, decimalPart] = fixedValue.split('.');

    // Форматируем целую часть с пробелами
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Возвращаем отформатированное значение
    if (decimalPart !== undefined && decimalPart !== null) {
        return `${formattedInteger}.${decimalPart || ''}`;
    }else{
        return `${formattedInteger}`;
    }
};

// Форматирование чисел с учетом выбранной локали (теперь используется это)
export const formatNumberIntl = (locale: string, value: number, decimalPlaces: number = 5) => {
    // locale = 'en-US';

    //console.log("[UTILS.formatNumberIntl] value:", value);

    // Устанавливаем фиксированное количество дробных знаков
    //const fixedValue = Number(value.toFixed(decimalPlaces));
    const fixedValue = Number(value);

    //console.log("[UTILS.formatNumberIntl] fixedValue:", fixedValue);

    // Форматируем число с учетом локали и разделением разрядов
    const formattedWithLocale = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
        useGrouping: true // Это обеспечит разделение разрядов
    }).format(fixedValue);

    return formattedWithLocale;
}

// ------------------------------------------------------
// Определение фактического множителя как прокачанного буста

//export const ActiveBoost = (skills: { boostX2: number; boostX3: number }): number => {
export const ActiveBoost = (_boostX2: boolean, _boostX3: boolean): number => {
    
    const boostX2 = _boostX2 ? 2 : 1; // Если буст активирован, то коэффициент 2, если не активирован, то 1
    const boostX3 = _boostX3 ? 3 : 1; // Если буст активирован, то коэффициент 3, если не активирован, то 1

    return Math.max(boostX2, boostX3);
};

// ------------------------------------------------------
// Количество дней между датами

export const getDaysDifference = (date1: Date, date2: Date = new Date()) => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// ------------------------------------------------------
// Форматирование даты с учетом пользовательской локали

export const formatDateByLocale = (
    date: Date, 
    locale: string = navigator.language, 
    options: Intl.DateTimeFormatOptions = {}
  ) => {
    const defaultOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  
    return new Intl.DateTimeFormat(locale, {
      ...defaultOptions,
      ...options
    }).format(date);
};

// ------------------------------------------------------
// Другая функция

export function anotherUsefulFunction(): void {
    console.log("Another useful function!");
}
  