// -------------------------------------
// Страница с информацией о раздаче
// -------------------------------------

import React from 'react';
import { Link } from 'react-router-dom';
import { config } from '../config';
import { useTranslation } from 'react-i18next';

export function AirdropPage() {

  // Загружаем переводы
  const {t} = useTranslation();

  return (
    <div className="airdrop-page">
      <h1>{t("airdrop")}</h1>
      <p>{t("congratulations") + " " + t("youHaveveReceived")} {config.airdropAmount} {t("_tokens")}.</p>
      {/*<Link to="/main">Continue to App</Link>*/}
    </div>
  );
}