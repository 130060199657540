// -------------------------------------
// Общий счет накопленных токенов
// -------------------------------------

import { useUser } from '../contexts/context';
import { formatNumberIntl } from './utils';

export const Score = () => {

    const { user } = useUser();

    return (
        <div className="score">
            {formatNumberIntl(user.locale, user.score)}
        </div>
    )
}