import React, { useEffect } from 'react';

interface SnackbarProps {
  message: string;
  isVisible: boolean;
  onClose: () => void;
  duration?: number; // Время отображения в миллисекундах
}

const Snackbar: React.FC<SnackbarProps> = ({ 
    message, 
    isVisible, 
    onClose, 
    duration = 3000 
}) => {

    useEffect(() => {
        if (isVisible) {
            //console.log("[SNACKBAR.useEffect] Запущен useEffect: снекбар");
            const timer = setTimeout(() => {
                //console.log("[SNACKBAR.useEffect] Вызываем сокрытие снекбара");
                onClose();
            }, duration);

            // Очистка таймера при размонтировании или повторном вызове
            return () => clearTimeout(timer);
        }
    }, [isVisible]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={`${"snackbar"} ${isVisible ? "visible" : ""}`}>
            {message}
        </div>
    );
};

export default Snackbar;
