// -------------------------------------
// Страница друзей
// -------------------------------------

'use client'

import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/context';
import FooterNavigation from '../components/footerNavigation';
import { formatNumberIntl } from '../components/utils';

import { ReactComponent as FriendsIcon} from '../assets/friends.svg'; // https://thenounproject.com/icon/friends-4464209/
//import { ReactComponent as FriendIcon} from '../assets/friend.svg'; // https://thenounproject.com/icon/human-4464227/
import { ReactComponent as FriendIcon} from '../assets/smile.svg'; // https://thenounproject.com/icon/smile-169431/
import { ReactComponent as CopyIcon} from '../assets/copy.svg'; // https://thenounproject.com/icon/copy-6713351/
import { ReactComponent as CheckedIcon} from '../assets/checked.svg'; // https://thenounproject.com/icon/checked-2513210/
import CONSTANTS from '../components/constants';
import { useTranslation } from 'react-i18next';

import * as twaSDK from '@twa-dev/sdk';

export function FriendsPage() {

  const consoleAllowed = true;
  const alertAllowed = true;

  const { user, friendsArray } = useUser();

  // Загружаем переводы
  const { t } = useTranslation();

  const inviteLink = `${CONSTANTS.telegramBotHref}${CONSTANTS.inviteLinkPrefix}${user.telegramUserID}`;
  const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(t("telegramShareText"))}`;
  const [isCheckIcon, setIsCheckIcon] = useState(false);
  
  // Функция для копирования в буфер обмена
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(inviteLink);
      setIsCheckIcon(true); // показываем галочку
      setTimeout(() => {
        setIsCheckIcon(false);
      }, CONSTANTS.iconChangeMilliseconds);
    } catch (err) {
      if (consoleAllowed) console.error(t("[FRIENDS.handleCopy] @failedToCopy"), ':', err);
    }
  };
  
  /// Приглашение через Telegram
  const handleInviteFriend = () => {
    try {
      twaSDK.default.openTelegramLink(fullUrl);
    } catch (error) {
      if (consoleAllowed) console.error("[FRIENDS.handleInviteFriend] @Error opening Telegram:", error);
    }
  };

  useEffect(() => {
    // Инициализация SDK
    twaSDK.default.ready();
    if (consoleAllowed) console.log(friendsArray);
  }, []);

  return (
    <div className="App">

      <div className="container">

        <div className="friends-header">
          <div>
            <FriendsIcon
              className="image-page-title">    
            </FriendsIcon>
          </div>
          <div className="description">
            {t("friendsPageDescription")}
            <br />
            {t("friendsPageDescription1")}
          </div>

        </div>
        
        <div className="num-friends">
          {formatNumberIntl(user.locale, user.friends, 0)} {t("_friends")}
        </div>

        <div className="invite-header">
          {/* t.me/<username>?text=<draft_text>&profile */}
          <div className="invite-buttons">
            <div
              className='button centered'
              onClick={handleInviteFriend}
            >
              {t("inviteFriends")}
            </div>
            <div className="button centered copy-button" onClick={handleCopy} role="button" tabIndex={0}>
              {!isCheckIcon ? (
                <CopyIcon
                  className="copy-icon">
                </CopyIcon>
              ) : (
                <CheckedIcon className="copy-icon" />
              )}
            </div>
          </div>
        </div>

        <div className="list friends-container">

          {friendsArray.length > 0 ? (
            <>
              <div className="invite-title">
                {t("20LastJoinedFriends")}
              </div>
              <div className="friends-list">
                {
                  // Генерируем список друзей
                  friendsArray?.map((f) => (

                    <span key={f.tgId} className="friend-card lefted">
                      {f.tgPhotoUrl ? (
                        <img
                          src={f.tgPhotoUrl}
                          //className="a-img-big"
                          className="avatar-medium"
                        />
                      ) : (
                        <FriendIcon className="a-img-big" />
                      )}
                      <span className="button-span">
                        <div className="booster-name">{f.tgFirstName} {f.tgLastName}</div>
                        <div className="booster-subtitle">{f.tgUsername}</div>
                      </span>
                    </span>
                  ))
                }
              </div> 
              
            </>
          ) : (
            <>
              <p>
                {t("noFriendsYet")}
              </p>
            </>
          )}
          
        </div>
      </div>

      {/* Компонент навигации */}
      <FooterNavigation />

    </div>
  )
}