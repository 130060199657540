// -------------------------------------
// Общий лимит энергии тапания
// -------------------------------------

import { useUser } from '../contexts/context';
import { formatNumberIntl } from "./utils";

interface EnergyLimitProps {
    energyLimit: number
}

export const EnergyLimit = ({energyLimit}: EnergyLimitProps) => {

    const { user } = useUser();

    return (
        <div className='energyLimit'>
            {/*new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(energyLimit)*/}
            {formatNumberIntl(user.locale, energyLimit, 0)}
        </div>
    )
}