/*================================================ 
    Меню навигации по страницам 
================================================*/

// Установка пакета webpack для работы с SVG:
// npm install react-svg


import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as TapIcon} from '../assets/tap.svg'; // https://thenounproject.com/icon/tap-884202/
import { ReactComponent as BoostIcon} from '../assets/boost.svg'; // https://thenounproject.com/icon/rocket-780226/
import { ReactComponent as FriendsIcon} from '../assets/friends.svg'; // https://thenounproject.com/icon/friends-4464209/
import { ReactComponent as EarnIcon} from '../assets/earn.svg'; // https://thenounproject.com/icon/coins-81304/
import { ReactComponent as AboutIcon} from '../assets/about.svg'; // https://thenounproject.com/icon/about-4737795/
import { useTranslation } from 'react-i18next';

const FooterNavigation: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();

  // Функция для определения активного маршрута (на какой странице находимся)
  const isActive = (path: string) => location.pathname === path;

  // Загружаем переводы
  const {t} = useTranslation();

  // Данные навигации
  const navItems = [
    { path: '/tap', label: t("mine"), Icon: TapIcon, handleClick: () => navigate('/tap') },
    { path: '/boost', label: t("boost"), Icon: BoostIcon, handleClick: () => navigate('/boost') },
    { path: '/friends', label: t("friends"), Icon: FriendsIcon, handleClick: () => navigate('/friends') },
    { path: '/earn', label: t("earn"), Icon: EarnIcon, handleClick: () => navigate('/earn') },
    { path: '/about', label: t("about"), Icon: AboutIcon, handleClick: () => navigate('/about') }
  ];

  const currentPath = location.pathname; // или другой способ определения текущего пути

  return (
    <div className={currentPath === '/tap' ? 'footer noselect' : 'footer-blurred noselect'}>
      {navItems.map((item, index) => (
        <div
          key={index}
          onClick={item.handleClick}
          className={`nav${isActive(item.path) ? '-active' : ''}`}
        >
          <item.Icon className={`img footer-icon${isActive(item.path) ? '-active' : ''}`} />
          {item.label}
        </div>
      ))}
    </div>
  );

}

export default FooterNavigation;
