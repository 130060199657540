// -------------------------------------
// Модальное окно (пока не используется, потому что различается форматирование у каждого бустера)
// -------------------------------------

import React, { useRef} from 'react';
import { useUser } from '../contexts/context';
import { formatNumberIntl } from './utils';
import CONSTANTS from './constants';
import { useTranslation } from 'react-i18next';
//import TonPayment from './tonPayment';

import { ReactComponent as CloseIcon} from '../assets/close.svg'; // https://thenounproject.com/icon/close-x-3647864/
import { ReactComponent as TonIcon} from '../assets/ton_symbol.svg';

// ---------------------------------------------

// Определяем общий интерфейс для пропсов
interface ModalProps {
  type: 'dailies' | 'levels' | 'booleans' | 'botMining' | 'ad';
  id: string;
  locale: string;
  title: string;
  icon?: React.ReactNode;
  brief?: string;
  description?: string;
  currentLevel?: number;
  currentLevelBoolean?: boolean;
  maxLevel?: number;
  bought?: string;
  decimals?: number;
  sec?: string;
  nextLevel?: number;
  buttonText?: string;
  score?: number;
  price?: number;
  value?: number;
  currency?: string;
  warning?: string;
  paid?: string;
  isVisible?: boolean;
  onClick: () => void;
  onClose: () => void;
  onInputChange?: (value: number) => void;
}

export const Modal: React.FC<ModalProps> = ({
  type,
  id,
  locale,
  title,
  icon,
  brief,
  description,
  currentLevel,
  currentLevelBoolean,
  maxLevel,
  bought,
  decimals,
  sec,
  nextLevel,
  buttonText,
  price,
  score,
  value,
  currency,
  warning,
  paid,
  isVisible = true,
  onClick,
  onClose,
  onInputChange
}) => {
  
  const { updateTonInvestment } = useUser();
  const inputRef = useRef<HTMLInputElement>(null);

  // Загружаем переводы
  const {t} = useTranslation();

  if (!isVisible) return null;

  // Сумма инвестиции
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Разрешаем специальные клавиши
    const allowedKeys = [
      'Backspace', 
      'Delete', 
      'ArrowLeft', 
      'ArrowRight', 
      'Tab', 
      'Home', 
      'End'
    ];
  
    // Проверяем, является ли введенный символ цифрой или точкой
    const isAllowedChar = /^[0-9.]$/.test(e.key);
    
    // Проверяем, есть ли уже точка в текущем значении
    const inputElement = e.target as HTMLInputElement;
    const hasDecimalPoint = inputElement.value.includes('.');
  
    // Блокируем ввод, если:
    // 1. Символ не является разрешенным И не входит в список специальных клавиш
    // 2. Введена вторая точка
    if (
      (!isAllowedChar && !allowedKeys.includes(e.key)) || 
      (e.key === '.' && hasDecimalPoint)
    ) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    
    // Санитаризация значения
    // const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
    // const parts = sanitizedValue.split('.');
    // const cleanValue = parts.length > 2 
    //   ? `${parts[0]}.${parts.slice(1).join('')}` 
    //   : sanitizedValue;
    
    console.log('[MODALS.handleInputChange] Значение в инпуте:', value);
    //console.log('[MODALS.handleInputChange] Значение в инпуте:', cleanValue);
    
    // Обновляем контекст
    //updateTonInvestment(value);
    
    // Вызываем переданный обратный вызов
    if (onInputChange) {
      onInputChange(value);
      //onInputChange(Number(cleanValue));
    }
  };



  // Функция для отрисовки специфического контента в зависимости от типа
  const renderContent = () => {
    switch (type) {

      // tapMaster, fullTank
      case 'dailies':
        return (
          <>
            <h3>{brief}</h3>
            {description && <p className='justified'>{description}</p>}
            <p>
              {currentLevel} / {maxLevel}
            </p>
            <div
              className={currentLevel! > 0 ? 'button centered' : 'button centered inactive-button'}
              onClick={onClick}
            >
              {buttonText}
            </div>
          </>
        );
      
      // tapIncrement, scoreIncrement, energyLimit, energyRestoration
      case 'levels':
        return (
          <>
            {brief && <h3>{brief}</h3>}
            {description && <p className='justified'>{description}</p>}
            
            <table className='left-aligned' border={0}>
              <tbody>
                <tr>
                  <td>{t("currentLevel") + ": "}</td>
                  <td>
                    {id === 'tapIncrement' || id === 'scoreIncrement' || id === 'energyRestoration' ? '+' : ''}
                    {(currentLevel ? formatNumberIntl(locale, currentLevel, id === 'energyLimit' || id === 'energyRestoration' ? 0 : CONSTANTS.precisionScore) : "") + (sec ? " " + sec : "")}
                  </td>
                </tr>
                <tr>
                  <td>{t("nextLevel") + ": "}</td>
                  <td>
                    {id === 'tapIncrement' || id === 'scoreIncrement' || id === 'energyRestoration' ? '+' : ''}
                    {(nextLevel ? formatNumberIntl(locale, nextLevel, id === 'energyLimit' || id === 'energyRestoration' ? 0 : CONSTANTS.precisionScore) : "") + (sec ? " " + sec : "")}
                  </td>
                </tr>
                <tr>
                  <td>{t("price") + ": "}</td>
                  <td>{(price ? formatNumberIntl(locale, price, 1) : 0) + " " + currency}</td>
                </tr>
                {/*
                <tr>
                  <td>{t("yourBalance") + ": "}</td>
                  <td>{score ? formatNumberIntl(locale, score, CONSTANTS.precisionScore) : 0}</td>
                </tr>
                */}
              </tbody>
            </table>
            <br></br>
            {
              /*
              <p>{t("currentLevel") + ": " + currentLevel + sec}</p>
            <p>{t("nextLevel") + ": " + nextLevel + sec}</p>
            <p>{t("price") + ": " + price + " " + currency}</p>
            <p>{t("yourBalance") + ": " + score}</p>
              */
            }
            
            
            <div
              className={score! < price! ? 'button centered inactive-button' : 'button centered'}
              onClick={onClick}
            >
              {buttonText}
            </div>
          </>
        );
      
      // tapBot, boostX2, boostX3, shareholder
      case 'booleans':
        return !currentLevelBoolean ? (
          <>
            {brief && <h3>{brief}</h3>}
            {description && <p className='justified'>{description}</p>}
            {warning && <h3 className='justified'>{warning}</h3>}

            {/* bot, boostX2, boostX3 */}
            {(paid === "bot" || paid === "paid") && (
              <>
                <table className='center-aligned' border={0}>
                  <tbody>
                    <tr>
                      <td>{t("price")}:</td>
                      <td>
                        {price ? (formatNumberIntl(locale, price, 1)) : 0}
                        {paid === "paid" && (
                          <>
                            <TonIcon className='modal-ton'/>
                            {" "}
                          </>
                        )}
                        {currency}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </>
            )}

            {/* shareholder */}
            {paid === "paid-more" && (
              <div className='shareholder-vote centered'>
                <label className='shareholder-input-label'>
                  <TonIcon className='modal-ton-shareholder'/>
                  <input
                      ref={inputRef}
                      type="text"
                      className='padded-input'
                      defaultValue={1}
                      onKeyDown={handleKeyDown}
                      onChange={handleInputChange}
                  />
                </label>    
              </div>
            )}
            
            <div 
              className={score && score > 0 && price === 0 ? (score > price ? 'button centered' : 'button centered inactive-button') : "button centered"}
              onClick={onClick}
            >
              {buttonText}
            </div>
          </>
        ) : (
          <>
            {paid !== "paid-more" && (
              <>
                <h3>{t("youAlreadyOwnIt")}</h3>
              </>
            )}
            <p>{bought}</p>
          </>
        );

      case 'botMining':
        return (
          <>
            <p>{t("yourBotHasMinedTokensForYou")}</p>
            <h2>{value ? formatNumberIntl(locale, value, CONSTANTS.precisionScore) : ""}</h2>
            <div className='button centered' onClick={onClick}>
              {buttonText}
            </div>
          </>
        );

      case 'ad':
        return (
          <>
            <p>{brief}</p>
            <h2>{value ? formatNumberIntl(locale, value, CONSTANTS.precisionAdReward) : ""}</h2>
            <div className='button centered' onClick={onClick}>
              {buttonText}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className={paid === "paid" ? 'modal-content-paid' : paid === "paid-more" ? 'modal-content-paid-more' : paid === "bot" ? 'modal-content-bot' : 'modal-content'} onClick={(e) => e.stopPropagation()}>
        <div>
          <CloseIcon className="modal-close" onClick={onClose} />
        </div>
        <div className="modal-title">
          {icon}
          <h2>{title}</h2>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};