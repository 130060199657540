// -------------------------------------
// Начальная страница пока идет загрузка данных
// -------------------------------------

import { ReactComponent as LogoIcon} from '../assets/skyrun_inline_cloud.svg';
import { ReactComponent as TonIcon} from '../assets/ton_logo_light_background.svg'; // https://ton.org/brand-assets
import { useTranslation } from 'react-i18next';

export function BootScreen() {

  // Загружаем переводы
  const {t} = useTranslation();

  return (
    <div className="App">
 
        <div className='boot-screen-container'>

            <div className='boot-screen-inner'>
                
                <LogoIcon className='boot-screen-logo'></LogoIcon>

                <div className='boot-screen-title'>
                    {t("discountTokens")}
                </div>

                <div className="spinner"></div>

                <TonIcon className="boot-screen-ton" />

            </div>
        </div>
    
      </div>

  )
}