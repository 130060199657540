// -------------------------------------
// ШКАЛА ПРОГРЕССА
// -------------------------------------

import React from 'react';

interface ProgressBarProps {
    current: number,
    limit: number,
}

//function ProgressBar({ energy, energyLimit }) {
export const ProgressBar = ({current, limit}: ProgressBarProps) => {
    
    // Вычисляем процент заполнения
    const fillPercentage = Math.min((current / limit) * 100, 100);

    return (
    <div className="progress-bar" style={{ backgroundSize: `${fillPercentage}% 100%` }} />
    );
}

export default ProgressBar;
