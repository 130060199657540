import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { initiatePayment } from '../components/tonPayment';
import { ActiveBoost } from '../components/utils';
import CONSTANTS from '../components/constants';

// Основные типы данных
export interface UserState {
  locale: string;
  telegramUserID: number;
  telegramUsername: string;
  telegramFirstName: string;
  telegramLastName: string;
  telegramIsPremium: boolean;
  telegramPhotoUrl: string;
  telegramIsBot: boolean;
  email: string;
  wallet: string;
  score: number;
  energy: number;
  energyFrozen: boolean;
  taps: number;
  botMined: number;
  lastSeenActivity: number;
  friends: number;
}

interface BonusState {
    big: number;
    medium: number;
    small: number;
}

export interface SkillsState {
  tapIncrement: number;
  activeTapIncrement: number;
  scoreIncrement: number;
  energyLimit: number;
  energyRestoration: number;
  tapBot: boolean;
  boostX2: boolean;
  boostX3: boolean;
  shareholderVote: boolean;
  shareholderVoteTONInvestment: number;
}

export interface DailyBoostsState {
  tapMaster: number;
  fullTank: number;
  adsShown: number;
}

export interface Friend {
  tgId: number;
  tgUsername: string;
  tgFirstName: string;
  tgLastName: string;
  tgPhotoUrl: string;
  tgIsBot: boolean;
}

interface ContextType {
    // Основные состояния
    user: UserState;
    bonus: BonusState;
    skills: SkillsState;
    dailyBoosts: DailyBoostsState;
    friendsArray: Friend[];

    // Специально для обновления счета
    setUser: React.Dispatch<React.SetStateAction<UserState>>;
    setDailyBoosts: React.Dispatch<React.SetStateAction<DailyBoostsState>>;
    setSkills: React.Dispatch<React.SetStateAction<SkillsState>>;

    // Методы обновления
    updateUser: (updates: Partial<UserState | ((prevState: UserState) => Partial<UserState>)>) => void;
    updateBonus: (updates: Partial<BonusState>) => void;
    updateSkills: (updates: Partial<SkillsState>) => void;
    updateDailyBoosts: (updates: Partial<DailyBoostsState>) => void;
    updateFriendsArray: (friends: Friend[]) => void;

    // Видимость лучей при мастере тапания
    sunraysVisible: boolean;
    updateSunraysVisible: (visible: boolean) => void;

    // Инвестиция в монетах TON
    tonInvestment: number;
    updateTonInvestment: (amount: number) => void;

    // TON-кошелек
    wallet: any;
    userFriendlyAddress: string | null;
    initiateTonPayment: (transactionTonValue: number, transactionMessage: string) => Promise<boolean>;

    sendUserDataToServer: () => void;
}

const UserContext = createContext<ContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const consoleAllowed = true;
  const alertAllowed = true;

  // Начальные состояния
  const [user, setUser] = useState<UserState>({
    locale: CONSTANTS.defaultLocale,
    telegramUserID: 0,
    telegramUsername: '',
    telegramFirstName: '',
    telegramLastName: '',
    telegramIsPremium: false,
    telegramPhotoUrl: '',
    telegramIsBot: true,
    email: '',
    wallet: '',
    score: CONSTANTS.iniScore,
    energy: CONSTANTS.iniEnergy,
    energyFrozen: false,
    taps: 0,
    botMined: 0,
    lastSeenActivity: 0,
    friends: 0,
  });

  const [bonus, setBonus] = useState<BonusState>({
    big: 0,
    medium: 0,
    small: 0,
  });

  const [skills, setSkills] = useState<SkillsState>({
    tapIncrement: CONSTANTS.iniTapIncrement,
    activeTapIncrement: CONSTANTS.iniTapIncrement,
    scoreIncrement: CONSTANTS.iniScoreIncrement,
    energyLimit: CONSTANTS.iniEnergyLimit,
    energyRestoration: CONSTANTS.iniEnergyRestoration,
    tapBot: CONSTANTS.iniTapBot,
    boostX2: false,
    boostX3: false,
    shareholderVote: false,
    shareholderVoteTONInvestment: 0,
  });

  const [dailyBoosts, setDailyBoosts] = useState<DailyBoostsState>({
    tapMaster: CONSTANTS.iniTapMaster,
    fullTank: CONSTANTS.iniFullTank,
    adsShown: 0
  });

  const [friendsArray, setFriendsArray] = useState<Friend[]>([]);;

  const [sunraysVisible, setSunraysVisible] = useState<boolean>(false);
  const [tonInvestment, setTonInvestment] = useState<number>(0);

  // TON-кошелек
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();


  // Методы обновления состояний
  const updateUser = useCallback((updates: Partial<UserState>) => {
    setUser(prev => ({ ...prev, ...updates }));
  }, []);

  const updateBonus = useCallback((updates: Partial<BonusState>) => {
    setBonus(prev => ({ ...prev, ...updates }));
  }, []);

  const updateSkills = useCallback((updates: Partial<SkillsState>) => {
    setSkills(prev => ({ ...prev, ...updates }));
  }, []);

  const updateDailyBoosts = useCallback((updates: Partial<DailyBoostsState>) => {
    setDailyBoosts(prev => ({ ...prev, ...updates }));
  }, []);

  const updateFriendsArray = useCallback((friends: Friend[]) => {
    setFriendsArray(friends);
  }, []);

  const updateSunraysVisible = useCallback((visible: boolean) => {
    setSunraysVisible(visible);
  }, []);

  const updateTonInvestment = useCallback((amount: number) => {
    setTonInvestment(amount);
  }, []);
  

  // Инициализация TON-платежа
  const initiateTonPayment = useCallback(async (
    transactionTonValue: number, 
    transactionMessage: string
  ): Promise<boolean> => {
    try {
      const success = await initiatePayment(
        transactionTonValue, 
        transactionMessage, 
        tonConnectUI
      );
      
      return success;
    } catch (error) {
      console.error('Payment error:', error);
      return false;
    }
  }, [tonConnectUI]);


  // Обновление кошелька
  useEffect(() => {
    if (wallet && userFriendlyAddress) {
      if (consoleAllowed) console.log("[CONTEXT.useEffect] Адрес кошелька обновлен");
      updateUser({ wallet: userFriendlyAddress });
    }
  }, [userFriendlyAddress]);


  // Таймер обновления счета и энергии (происходит каждую секунду)
  useEffect(() => {
    const gameTimer = setInterval(() => {
      const activeBoost = ActiveBoost(skills.boostX2, skills.boostX3);

      updateUser({
        score: Number(
          user.score + skills.scoreIncrement * activeBoost
        ),
        energy: Math.min(
          user.energy + skills.energyRestoration,
          skills.energyLimit
        )
      });
    }, 1000);

    return () => clearInterval(gameTimer);
  }, [user, skills, updateUser]);


  // Проверка наступления полуночи по UTC во время работающего приложения
  // Восстанавливаем ежедневные бустеры и отображение рекламы
  // Ежедневный сброс бустеров (время проверяется каждую секунду)
  useEffect(() => {
    const midnightInterval = setInterval(() => {
      const now = new Date();
      const utcHours = now.getUTCHours();
      const utcMinutes = now.getUTCMinutes();
      const utcSeconds = now.getUTCSeconds();

      //if (utcHours === 0 && utcMinutes === 0 && utcSeconds === 0) {
      if (utcHours === 19 && utcMinutes === 8 && utcSeconds === 0) {
        if (consoleAllowed) console.log("[CONTEXT] Восстанавливаем ежедневные бустеры");
        if (alertAllowed) alert("[CONTEXT] Восстанавливаем ежедневные бустеры");
        updateDailyBoosts({
          tapMaster: CONSTANTS.tapMasterMax,
          fullTank: CONSTANTS.fullTankMax,
          adsShown: 0
        });
      }
    }, 1000);

    return () => clearInterval(midnightInterval);
  }, [updateDailyBoosts]);


  // Отправляем данные на сервер
  const sendUserDataToServer = async () => {
    if (consoleAllowed) console.log("[CONTEXT.sendUserDataToServer] ");

    try {
      const queryParams = new URLSearchParams({
        user: JSON.stringify({
          tg_user_id: String(user.telegramUserID),
          tg_username: String(encodeURIComponent(String(user.telegramUsername))),
          tg_first_name: String(encodeURIComponent(String(user.telegramFirstName))),
          tg_last_name: String(encodeURIComponent(String(user.telegramLastName))),
          tg_locale: String(encodeURIComponent(String(user.locale))),
          tg_is_premium: String(user.telegramIsPremium),
          tg_photo_url: String(user.telegramPhotoUrl),
          tg_is_bot: String(user.telegramIsBot),

          score: String(user.score),
          energy: String(user.energy),
          taps: String(user.taps),
          wallet: String(user.wallet),
        }),
        bonus: JSON.stringify({
          big: String(bonus.big),
          medium: String(bonus.medium),
          small: String(bonus.small),
        }),
        dailyBoosts: JSON.stringify({
          tapMaster: String(dailyBoosts.tapMaster),
          fullTank: String(dailyBoosts.fullTank),
          adsShown: String(dailyBoosts.adsShown),
        }),
        skills: JSON.stringify({
          tapIncrement: String(skills.tapIncrement),
          scoreIncrement: String(skills.scoreIncrement),
          energyLimit: String(skills.energyLimit),
          energyRestoration: String(skills.energyRestoration),
          tapBot: String(skills.tapBot),
          boostX2: String(skills.boostX2),
          boostX3: String(skills.boostX3),
          shareholderVote: String(skills.shareholderVote),
          shareholderVoteTONInvestment: String(skills.shareholderVoteTONInvestment),
        })
      });

      const requestUrl = `${CONSTANTS.server}${CONSTANTS.apiLinkUpdateUserRecords}?q=${queryParams.toString()}`;

      //https://discount.skyrun.digital/api/updateuserrecords/v1/?q=tg_user_id=428856366&tg_username=&tg_first_name=&tg_last_name=&tg_locale=uk-UA&tg_is_premium=false&tg_photo_url=https%3A%2F%2Ft.me%2Fi%2Fuserpic%2F320%2FXFMrPvOZDL1ThnynFnwt_WBXPQiLD0xmpH5nHquTndA.svg&tg_is_bot=true&score=10875.15473999997&energy=1000&taps=936&wallet=UQCv2xs4M25wrsfjnkWv52ds9P5LjXWLeL6Wwrpwmt3C9WdC&bigBonus=0&mediumBonus=0&smallBonus=0&tapMaster=3&fullTank=3&adsShown=0&tapIncrement=0.00003&scoreIncrement=0.00002&energyLimit=1000&energyRestoration=2&tapBot=false&boostX2=false&boostX3=false&shareholderVote=false&shareholderVoteTONInvestment=0
      // https://discount.skyrun.digital/api/updateuserrecords/v1/?q=user=%7B%22tg_user_id%22%3A%22428856366%22%2C%22tg_username%22%3A%22slabunsky%22%2C%22tg_first_name%22%3A%22%22%2C%22tg_last_name%22%3A%22%22%2C%22tg_locale%22%3A%22uk-UA%22%2C%22tg_is_premium%22%3A%22false%22%2C%22tg_photo_url%22%3A%22https%3A%2F%2Ft.me%2Fi%2Fuserpic%2F320%2FXFMrPvOZDL1ThnynFnwt_WBXPQiLD0xmpH5nHquTndA.svg%22%2C%22tg_is_bot%22%3A%22true%22%2C%22score%22%3A%2210875.161689999795%22%2C%22energy%22%3A%221000%22%2C%22taps%22%3A%22935%22%2C%22wallet%22%3A%22UQCv2xs4M25wrsfjnkWv52ds9P5LjXWLeL6Wwrpwmt3C9WdC%22%7D&bonus=%7B%22big%22%3A%220%22%2C%22medium%22%3A%220%22%2C%22small%22%3A%220%22%7D&dailyBoosts=%7B%22tapMaster%22%3A%223%22%2C%22fullTank%22%3A%223%22%2C%22adsShown%22%3A%220%22%7D&skills=%7B%22tapIncrement%22%3A%220.00003%22%2C%22scoreIncrement%22%3A%220.00002%22%2C%22energyLimit%22%3A%221000%22%2C%22energyRestoration%22%3A%222%22%2C%22tapBot%22%3A%22false%22%2C%22boostX2%22%3A%22false%22%2C%22boostX3%22%3A%22false%22%2C%22shareholderVote%22%3A%22false%22%2C%22shareholderVoteTONInvestment%22%3A%220%22%7D
      if (consoleAllowed) console.log("[CONTEXT] requestUrl:", requestUrl);

      const response = await fetch(requestUrl, {
          method: 'GET',
          credentials: 'include',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
      });
      
    } catch (error) {
      console.error("[CONTEXT.sendUserDataToServer] error:", error);
    }
  }


  return (
    <UserContext.Provider value={{
      user,
      bonus,
      skills,
      dailyBoosts,
      friendsArray,
      setUser,
      setDailyBoosts,
      setSkills,
      updateUser,
      updateBonus,
      updateSkills,
      updateDailyBoosts,
      updateFriendsArray,
      sunraysVisible,
      updateSunraysVisible,
      tonInvestment,
      updateTonInvestment,
      wallet,
      userFriendlyAddress,
      initiateTonPayment,
      sendUserDataToServer
    }}>
      {children}
    </UserContext.Provider>
  );
};

// Хук для использования контекста
export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}