// -------------------------------------
// Страница заработка
// -------------------------------------

// https://docs.adsgram.ai/publisher/code-examples
import React, { useState, useEffect, useCallback } from "react";
import { useAdsgram } from "../components/useAdsgram";
import { ShowPromiseResult } from "../adsgram.d";
import FooterNavigation from '../components/footerNavigation';
import { useTranslation } from 'react-i18next';
import CONSTANTS from "../components/constants";
import { useUser, UserState, DailyBoostsState } from '../contexts/context';
import { Modal } from "../components/modals";
import { ReactComponent as EarnIcon} from '../assets/earn.svg'; // https://thenounproject.com/icon/coins-81304/

export function EarnPage(): React.ReactElement {

  const { user, setUser, dailyBoosts, setDailyBoosts, updateUser, updateDailyBoosts} = useUser();
  //const [localAdsShown, setLocalAdsShown] = useState<number>(dailyBoosts.adsShown || 0);
  const [isInitialized, setIsInitialized] = useState(false);

  //console.log("[EARN] dailyBoosts.adsShown:", dailyBoosts.adsShown);

  // Состояние для управления видимостью модального окна
  const [isModalVisible, setModalVisible] = useState(false);

  // Загружаем переводы
  const {t} = useTranslation();
  
  // Функция для открытия конкретного модального окна
  const openModal = (modalType: string) => {
    setModalVisible(true);
  };

  // Функция для закрытия модального окна
  const closeModal = () => {
    setModalVisible(false);
  };

  // Получение вознаграждения
  const onReward = useCallback(() => {
    console.log("[EARN.onReward]");

    // Показываем модальное окно
    openModal('adReward');

    // Увеличиваем счетчик показа рекламы
    setDailyBoosts((prev: DailyBoostsState) => ({
      ...prev,
      adsShown: prev.adsShown + 1
    }));

    // Обновляем счет в контексте
    setUser((prevUser: UserState) => ({
      ...prevUser,
      score: prevUser.score + Number(CONSTANTS.adReward.toFixed(CONSTANTS.precisionAdReward)),
    }));

  
  }, [dailyBoosts.adsShown ]);

  // Ошибка при показе
  const onError = useCallback((result: ShowPromiseResult) => {
    console.error("[EARN.onError]", JSON.stringify(result, null, 4));
  }, []);

  // Временно закомментировал
  const showAd = useAdsgram({
    blockId: CONSTANTS.adsGramBlockID, onReward, onError
  });
 

  // ПОКА ОТКЛЮЧИЛ (РАБОТАЕТ)
  // Автозапуск рекламы при первом рендере
  // useEffect(() => {

  //   if (!isInitialized) {
  //     setIsInitialized(true);
  //     console.log("[EARN.useEffect]");

  //     if (dailyBoosts.adsShown < CONSTANTS.maxAdsDaily) {
  //       //showAd();
  //       onReward(); // Для тестирования
  //     }
  //   }
  // }, [isInitialized, onReward, dailyBoosts.adsShown]); // Вызываем если происходит имзенение в указанной зависимости]


  return (
    <div className="App">

      <div className="container">
        
        <div className="ads">
          <EarnIcon className="image-page-title" />
          
          <br/>
          <br/>

          {t('ads')}
          
          <br/>
          <br/>
          
          {dailyBoosts.adsShown} / {CONSTANTS.maxAdsDaily}
          {
            (dailyBoosts.adsShown >= CONSTANTS.maxAdsDaily) ? (
              <>
                <br/>
                <br/>
                
                {t('maxAdsShown')}
                
                <br/>
                
                {t('comeBackTomorrow')}
              </>
            ) : (
                <>
                  <br/>
                  <br/>

                  <div className="button centered" onClick={showAd}>
                    {t('viewAd')}
                  </div>

                  <br/>
                  <br/>

                  {t('rewardInTokenForViewingAds_1')} {CONSTANTS.adReward.toFixed(CONSTANTS.precisionAdReward)} {t('rewardInTokenForViewingAds_2')}
                </>
            )
           }
        </div>

      </div>

      {/* Модальное окно */}
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="ad"
        type="ad"
        icon={<EarnIcon className="modal-img" />}
        title={t("reward")}
        brief={t('yourRewardIs')}
        buttonText={t("ok")}
        value={CONSTANTS.adReward}
        isVisible={isModalVisible}
        onClick={closeModal}
        onClose={closeModal}
      />

      {/* Компонент навигации */}
      <FooterNavigation />
      
    </div>
  );
}