import { useUser } from '../contexts/context';
import { useTranslation } from 'react-i18next';

export const DisplayUserName = () => {

    const { user } = useUser();

    // Загружаем переводы
    const { t } = useTranslation();

    if (user.telegramFirstName && user.telegramLastName) return `${user.telegramFirstName} ${user.telegramLastName}`;
    if (user.telegramFirstName) return user.telegramFirstName;
    if (user.telegramLastName) return user.telegramLastName;
    if (user.telegramUsername) return user.telegramUsername;
    return t('hi');

}
