// -------------------------------------
// Уведомление о временной неработоспособности
// -------------------------------------

import React from 'react';
//import { config } from '../config';
import { useTranslation } from 'react-i18next';

export function MaintenancePage() {

  // Загружаем переводы
  const {t} = useTranslation();

  return (
    <div className="maintenance-page">
      <h1>{t('maintenance')}</h1>
      <p>{t('maintenanceMessage')}</p>
    </div>
  );
}