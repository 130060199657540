/*================================================ 
Динамическое изменение иконки в зависимости от темы оформления 
================================================*/

import React, { useEffect } from 'react';

// В исходном файле index.html должна быть указана эта строка как исходное значение:
// <link rel="icon" href="/favicon-light.ico" />
// Иначе этот код не сможет ничего обновить

const DynamicFavicon: React.FC = () => {
  useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    const setFavicon = () => {
      const theme = matchMedia.matches ? 'dark' : 'light';
      const link: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
      if (link) {
        //link.href = `%PUBLIC_URL%/favicon-${theme}.ico`; // Для статических страниц
        link.href = `/favicon-${theme}.ico?v=${Date.now()}`; // Для динамических страниц (для обхода кэширования добавил параметр v)
      }
    };

    setFavicon(); // Установить начальный favicon
    matchMedia.addEventListener('change', setFavicon);
    return () => matchMedia.removeEventListener('change', setFavicon);
  }, []);

  return null; // Компонент ничего не рендерит  
};

export default DynamicFavicon;