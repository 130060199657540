import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Автоматическое определения языка пользователя
import LanguageDetector from 'i18next-browser-languagedetector';

// Импортируем переводы для всех языков
import en from './locales/en.json';
import ru from './locales/ru.json';
import uk from './locales/uk.json';

i18n
  .use(LanguageDetector) // Автоматическое определение языка
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'navigator', 'htmlTag'], // Основные источники определения языка
      lookupQuerystring: 'lang', // Устанавливаем дополнительные параметры поиска
      caches: [], // Отключаем кэширование
    },
    resources: {
      en: { translation: en },
      ru: { translation: ru },
      uk: { translation: uk },
    },
    fallbackLng: 'en', // Язык по умолчанию
    interpolation: { escapeValue: false },
  })
  .then(() => {
    //console.log("Detected language: ${i18n.language}"); // Локаль, которую удалось определить
    //console.log("Using language: ${i18n.resolvedLanguage}"); // Локаль, которая используется (в случае fallback может отличаться)
    console.log("[i18n] Navigator language:", navigator.language /*|| navigator.userLanguage*/ );
  }
);

export default i18n;
