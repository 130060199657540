// -------------------------------------
// Текущий уровень энергии
// -------------------------------------

import { useUser } from '../contexts/context';
import { formatNumberIntl } from "./utils";

export const Energy = () => {
    
    const { user } = useUser();

    return (
        <div className='energy'>
            {formatNumberIntl(user.locale, user.energy, 0)}
        </div>
    )
}