// -------------------------------------
// Получаем данные о пользователе из Телеграм
// -------------------------------------

import React, { useEffect, useState } from 'react';
import CONSTANTS from './constants';
import * as twaSDK from '@twa-dev/sdk';

interface TelegramUserInfo {
  photo_url: string;
  id: number;
  is_bot: boolean;
  language_code: string;
  first_name: string;
  last_name?: string;
  username?: string;
  is_premium: boolean;
}

const useTelegramUserData = () => {
  
  // Информация для отладки
  const consoleAllowed = false;
  const alertAllowed = true;

  const [telegramUserInfo, setTelegramUserInfo] = useState<TelegramUserInfo | null>(null);
  const [response, setResponse] = useState<string>(''); 
  //const [photo_url, setPhoto_url] = useState<string>(''); 
  const [error, setError] = useState<string | null>(null);
  // const [loading, setLoading] = useState<boolean>(true); // Добавлено состояние загрузки

  //const BOT_TOKEN = '7561846121:AAExGO--uQ691i-ZbDuvYvdF6n0It4V5nnQ';
  const BOT_TOKEN = CONSTANTS.telegramBotToken;
  
  // Только для тестирования
  // const DEFAULT_TELEGRAM_USER: TelegramUserInfo = {
  //   photo_url: "",
  //   id: 428856366, // Особенно это: 0, 428856366
  //   is_bot: false,
  //   language_code: CONSTANTS.defaultLocale,
  //   first_name: '@Default user',
  //   last_name: '',
  //   username: '@default_user',
  //   is_premium: false,
  // }
  const DEFAULT_TELEGRAM_USER: TelegramUserInfo = {
    photo_url: "https://t.me/i/userpic/320/XFMrPvOZDL1ThnynFnwt_WBXPQiLD0xmpH5nHquTndA.svg",
    id: 428856366, // Особенно это: 0, 428856366
    is_bot: false,
    language_code: 'uk',
    first_name: '',
    last_name: '',
    username: 'slabunsky',
    is_premium: false,
  }

  useEffect(() => {
    const initTwa = async () => {
      try {
        
        // setLoading(true);
        
        twaSDK.default.ready();

        // Пауза для обеспечения обновления контекста
        //await new Promise((resolve) => setTimeout(resolve, 1000));
        
        // Посмотрим какие вообще методы доступны
        //const tu = twaSDK.default.initDataUnsafe;
        //console.log(tu);

        // https://core.telegram.org/bots/webapps#webappinitdata
        const telegramUser = twaSDK.default.initDataUnsafe?.user;
        
        //let avatar = '';

        // if (alertAllowed) alert("[useTelegramUserData]\n" + 
        //   "id: " + telegramUser?.id + "\n" +
        //   "first_name:" + telegramUser?.first_name + "\n" +
        //   "last_name: " + telegramUser?.last_name + "\n" +
        //   "username: " + telegramUser?.username + "\n" +
        //   "language_code: " + telegramUser?.language_code + "\n" +
        //   "is_premium: " + telegramUser?.is_premium + "\n"
        // );

        // Ждем появления данных о пользователе
        //while (!telegramUser || telegramUser.id <= 0) {};

        if (telegramUser && telegramUser.id > 0) {

          // if (alertAllowed) alert("[useTelegramUserData] telegramUser.id > 0: \n\n" + 
          //   "id: " + telegramUser?.id + "\n" +
          //   "first_name:" + telegramUser?.first_name + "\n" +
          //   "last_name: " + telegramUser?.last_name + "\n" +
          //   "username: " + telegramUser?.username + "\n" +
          //   "language_code: " + telegramUser?.language_code + "\n" +
          //   "is_premium: " + telegramUser?.is_premium + "\n"
          // );

          // // --------------------------------------------
          // // АВАТАРКА ПОЛЬЗОВАТЕЛЯ
          // const options = {
          //   method: 'POST',
          //   headers: {
          //     accept: 'application/json',
          //     'content-type': 'application/json',
          //   },
          //   body: JSON.stringify({ user_id: telegramUser?.id, offset: 0, limit: 1 }),
          // };

          // //alert("options: \n" + JSON.stringify(options));

          // // Получаем список всех фотографий пользователя
          // const profilePhotosResponse = await fetch(
          //   `https://api.telegram.org/bot${BOT_TOKEN}/getUserProfilePhotos`,
          //   options
          // );
          // const profilePhotosData = await profilePhotosResponse.json();
          // setResponse(JSON.stringify(profilePhotosData, null, 2));

          // if (alertAllowed) alert("profilePhotosData: \n" + JSON.stringify(profilePhotosData, null, 2));

          // if (profilePhotosData.ok && profilePhotosData.result.photos.length > 0) {
          //   // Используем самую первую фотографию в виде вавтарки
          //   const fileId = profilePhotosData.result.photos[0][0].file_id;

          //   if (alertAllowed) alert("fileId: \n" + fileId);

          //   // Выбираем иднтификатор этого файла
          //   const fileOptions = {
          //     method: 'POST',
          //     headers: {
          //       accept: 'application/json',
          //       'content-type': 'application/json',
          //     },
          //     body: JSON.stringify({ file_id: fileId }),
          //   };

          //   //alert("fileOptions: \n" + JSON.stringify(fileOptions));

          //   const fileResponse = await fetch(
          //     `https://api.telegram.org/bot${BOT_TOKEN}/getFile`,
          //     fileOptions
          //   );
          //   const fileData = await fileResponse.json();

          //   if (alertAllowed) alert("fileData: \n" + JSON.stringify(fileData));

          //   if (fileData.ok) {
            
          //       // Ссылка на само изображение аватарки
          //     const filePath = fileData.result.file_path;
          //     avatar = `https://api.telegram.org/file/bot${BOT_TOKEN}/${filePath}`;
          //     if (alertAllowed) alert("avatar: \n" + avatar);
          //     setAvatarUrl(avatar);

          //     console.log("[USETELEGRAMUSERDATA] avatar (real):", avatar);

          //   } else {
          //     setError('Failed to get avatar file.');
          //   }
          // } else {
          //   setError('No profile photos found.');
          // }
          // --------------------------------------------

          // ФОРМИРУЕМ ПОЛНЫЙ ОТВЕТ
          const userInfo: TelegramUserInfo = {
            photo_url: telegramUser.photo_url ?? '',
            id: telegramUser.id,
            is_bot: telegramUser.is_bot ?? true,
            language_code: telegramUser.language_code ?? 'en',
            first_name: telegramUser.first_name,
            last_name: telegramUser.last_name,
            username: telegramUser.username,
            is_premium: telegramUser.is_premium ?? false,
          };
          
          //const userInfo: TelegramUserInfo = DEFAULT_TELEGRAM_USER;
          setTelegramUserInfo(userInfo || DEFAULT_TELEGRAM_USER);

          //if (consoleAllowed) console.log("[USETELEGRAMUSERDATA] avatar (default):", avatar);

        }
      } catch (error) {
        setError(`Initialization error: ${error}`);

      // // ДЛЯ ТЕСТИРОВАНИЯ
      // } finally {
      //   const userInfo: TelegramUserInfo = DEFAULT_TELEGRAM_USER; // Для разработки
      //   //const userInfo: TelegramUserInfo = telegramUser; // Реальная информация
      //   setTelegramUserInfo(userInfo);

      }
    };

    initTwa();
  }, []);

  //return { telegramUserInfo, response, avatarUrl, error, loading };
  return { telegramUserInfo, response, error };

};

export default useTelegramUserData;
